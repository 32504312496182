import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const Title = styled.h2<{ alignment?: string }>`
  ${Text('large1', 'alternate')};
  margin: ${spacing(2)} 0 ${spacing(2)};
  text-align: ${(props) => props.alignment || 'center'};
`

export const PlayButton = styled.button`
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
`

export const VideoContainer = styled.video<{ isVisible: boolean }>`
  ${({ isVisible }) => !isVisible && 'visibility: hidden'};

  height: ${(props) =>
    props.theme.widget.GlobalVideoHeroBannerWidget.layout.height
      ? `calc(${props.theme.widget.GlobalVideoHeroBannerWidget.layout.height?.widgetHeight} - ${props.theme.widget.GlobalVideoHeroBannerWidget.layout.height?.headerHeight});`
      : 'auto'};
  object-fit: ${(props) =>
    props.theme.widget.GlobalVideoHeroBannerWidget.layout.height
      ? 'cover'
      : 'fill'};
`

export const PreviewImageContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;

  ${({ isVisible }) =>
    !isVisible &&
    'visibility: hidden; opacity: 0; transition: visibility 0s 0.5s, opacity 0.5s linear;'}
`

export const PreviewImage = styled.div`
  position: relative;
`

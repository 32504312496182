import React from 'react'
import dynamic from 'next/dynamic'
import { Image } from '@thg-commerce/gravity-elements'
import { HorizontalAlignment, i18n } from '@thg-commerce/enterprise-core'

import {
  PlayButton,
  PreviewImage,
  PreviewImageContainer,
  Title,
  VideoContainer,
} from './styles'

const Play = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/PlayDefaultCircle'),
)

export interface VideoHeroBannerProps {
  videoTitle: {
    text?: string
    alignment?: HorizontalAlignment
  }
  videoAssetPathMp4?: string
  videoAssetPathWebm?: string
  previewImage?: {
    urls: {
      small: string
      medium: string
      large: string
    }
    alt?: string
  }
  muteVideo?: boolean
  autoplayVideo?: boolean
  className?: string
}

export const VideoHeroBanner = (props: VideoHeroBannerProps) => {
  const [displayVideo, setDisplayVideo] = React.useState(!!props.autoplayVideo)
  const [videoTabIndex, setVideoTabIndex] = React.useState(-1)

  const videoRef = React.createRef<HTMLVideoElement>()

  const playVideo = () => {
    setDisplayVideo(true)
    setVideoTabIndex(0)
    videoRef.current?.play()
  }

  const playVideoI18nText = i18n('general.play.video.button.text')

  return (
    <div className={props.className}>
      {props.videoTitle.text && (
        <Title data-testid="video-title" alignment={props.videoTitle.alignment}>
          {props.videoTitle.text}
        </Title>
      )}
      {props.previewImage && (
        <PreviewImageContainer
          isVisible={!displayVideo}
          data-testid="preview-image-and-play-button"
        >
          <PreviewImage>
            <Image
              src={[
                {
                  url: props.previewImage.urls.small,
                  breakpoint: 'xs',
                },
                {
                  url: props.previewImage.urls.medium,
                  breakpoint: 'sm',
                },
                {
                  url: props.previewImage.urls.large,
                  breakpoint: 'md',
                },
              ]}
              width="100%"
              height="100%"
              alt={props.previewImage.alt || ''}
              isAmp={false}
            />
            <PlayButton
              onClick={playVideo}
              aria-label={playVideoI18nText}
              data-testid="play-video-button"
            >
              <Play />
            </PlayButton>
          </PreviewImage>
        </PreviewImageContainer>
      )}
      <VideoContainer
        ref={videoRef}
        width="100%"
        autoPlay={props.autoplayVideo || false}
        muted={props.muteVideo || false}
        controls
        isVisible={displayVideo}
        tab-index={videoTabIndex}
        aria-label={props.videoTitle?.text}
        playsInline
      >
        {props.videoAssetPathMp4 && (
          <source src={props.videoAssetPathMp4} type="video/mp4" />
        )}
        {props.videoAssetPathWebm && (
          <source src={props.videoAssetPathWebm} type="video/webm" />
        )}
        Your browser does not support HTML video.
      </VideoContainer>
    </div>
  )
}
